
import {computed, defineComponent, ref} from "vue";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import store from "@/store";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import Avatar from "@/components/base/common/Avatar.vue";

export default defineComponent({
  name: "Timelines",
  components: {Avatar, DateTimeFormat},
  props: {
    objectType: {type: String},
    objectId: {type: String},
  },
  setup(props) {
    const filterObject = ref({objectType: props.objectType, objectId: props.objectId})
    const timelinePage = computed(() => store.state.TimelineModule.timelinePage);
    return {
      ...LoadFilterObjects(Actions.LOAD_TIMELINES, filterObject.value, []),
      timelinePage,
      filterObject,
    }
  },
  mounted() {
    store.subscribe((mutation) => {
      if (mutation.type === Mutations.ADDED_TAGS) {
        this.paginationLoad()
      }
    });
  },
  watch: {
    objectId(cb) {
      this.filterObject.objectId = cb
      this.updateFilterObject(this.filterObject)
    }
  }
})
