import { BaseService } from "@/core/services/BaseService";

class IdService extends BaseService<any> {
  endpoint = '/hash';

  async decode(id) {
    return this.http().get(this.endpoint + "/decode/" + id).then(res => {
      return res.data
    })
  }
}

export default new IdService();