
import {computed, defineComponent, ref, watch} from "vue";
import {Field} from 'vee-validate';
import BaseForm from "@/components/base/form/BaseForm.vue";
import {CONTACT_TYPE} from "@/core/config/Constant";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {ILocation} from "@/core/entity/ILocation";
import store from "@/store";
import {HandleState, ProviderSelect} from "@/core/composite/composite";
import ContactService from "@/core/services/ContactService";
import Froala from "@/components/base/editor/Froala.vue";
import * as Yup from "yup";
import LocationService from "@/core/services/LocationService";

export default defineComponent({
  name: "FormContact",
  components: {Froala, BaseSelect, FormErrorMessage, BaseForm, Field},
  props: {
    contractId: {type: String},
    contact: {required: true, type: Object}
  },
  emits: ['savedContact'],
  setup(props, {emit}) {

    const model = ref<any>(props.contact)
    watch(() => props.contact, (cb) => {
      model.value = cb;
    })
    const validations = Yup.object().shape({
      name: Yup.string().label("Name").max(255),
      phone: Yup.string().label("Phone").max(255),
      email: Yup.string().label("email").max(255),
      fax: Yup.string().label("Fax").max(255),
    });
    const submitting = ref(false);
    const countries = computed<ILocation[]>(() => store.state.LocationModule.countries)
    const states = computed<ILocation[]>(() => store.state.LocationModule.states)
    const submitContact = () => {
      submitting.value = true
      if (props.contractId) {
        ContactService.update(props.contractId, model.value).then(res => {
          emit('savedContact', res);
        }).finally(() => {
          submitting.value = false;
        })
      } else {
        ContactService.create(model.value).then(res => {
          emit('savedContact', res);
        }).finally(() => {
          submitting.value = false;
        })
      }
    }
    return {
      model,
      submitting,
      countries,
      ...HandleState(),
      states,
      CONTACT_TYPE,
      submitContact,
      ...ProviderSelect(),
      validations,
    }
  },
  mounted() {
    this.filter.key.cazeId = this.contact.cazeId ? this.contact.cazeId : '';
    this.loadProvider();
  },
  methods: {
    selectType(type) {
      if (type === 'PROVIDER') {
        this.filter.key.cazeId = this.contact.cazeId;
        this.loadProvider();
      }
    },
    onStateNameSelect(stateName) {
      const name = this.states.find((item) => item.code === stateName)?.name
      if (name) this.model.address.stateName = name
    },

    onCountryNameSelect(countryName) {
      const name = this.countries.find((item) => item.code === countryName)?.name
      if (name) this.model.address.countryName = name;
    },
    search() {
      console.log(this.model.address);
      LocationService.getVerify(this.model.address.street, this.model.address.cityName).then(res => {
        console.log(res);
      });
    }
  }
})
