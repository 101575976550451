
import {defineComponent, ref} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import {ActionTemplates, ActionTemplatesGroup} from "@/core/composite/composite";
import CheckListService from "@/core/services/CheckListService";
import BaseSelect from "@/components/base/select/BaseSelect.vue";

export default defineComponent({
  name: "FormCheckList",
  components: {BaseSelect, BaseForm},
  props: {
    checkList: {type: Object},
    area: {type: String, default: () => 'CASE'},
  },
  setup(props) {
    const type = ref('YES_NO_REQUIRE');
    const model = ref<any>(props.checkList)
    const submitting = ref(false);
    const validate = {}
    return {
      model,
      submitting,
      type,
      validate,
      ...ActionTemplates(props.area),
      ...ActionTemplatesGroup(props.area),
    }
  },
  methods: {
    onCheck(event, template) {
      if (event.target.checked === true) {
        this.model.templateIds.push(template.id)
      } else {
        this.model.templateIds = this.model.templateIds.filter(item => item !== template.id);
      }
    },
    addLine() {
      this.model.items.push({itemId: '', name: ''})
    },
    removeLine(line) {
      if (this.model.items) this.model.items = this.model.items.filter(item => item !== line)
    },
    onSubmit() {
      this.submitting = false;
      CheckListService.create(this.model).then(res => {
        this.$emit('saved', res);
      }).finally(() => {
        this.submitting = false
      })
    },
    onSelectedTemplateGroups(id) {
      if (!id) {
        this.loadActionTemplate(this.area);
      } else {
        this.setActionTemplates(this.actionTemplateGroups.find(item => item.id === id).actions);
      }
    }
  }

})
