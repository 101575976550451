
import {defineComponent, ref, watch} from "vue";
import ProductService from "@/core/services/ProductService";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "ProductLink",
  props: {
    id: {type: String}
  },
  setup(props) {
    const product = ref({});
    const load = (id) => {
      if (id) {
        ProductService.get<any>(id).then(res => {
          product.value = res;
        })
      } else {
        product.value = {id: '', name: ''};
      }
    }
    load(props.id)
    watch(() => props.id, cb => {
      load(cb)
    })
    return {
      product,
      ...LoadPanel(),
    }
  }
})
