
import {computed, defineComponent, ref} from "vue";
import {LoadFilterObjects, LoadPanel} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import FormContact from "@/views/contact/FormContact.vue";
import store from "@/store";
import ContactService from "@/core/services/ContactService";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "Contacts",
  components: {FormContact, BaseModal, QuickAction},
  props: {
    cazeId: {required: false, default: ''},
    policyId: {required: false, default: ''},
    claimId: {required: false, default: ''},
    companyId: {required: false, default: ''},
  },
  setup(props) {
    const contactForm = ref({
      cazeId: props.cazeId,
      policyId: props.policyId,
      claimId: props.claimId,
      companyId: props.companyId,
      address: {},
      type: 'CONTACT',
      fax: '',
      phone: '',
      email: '',
      name: '',
      details: '',
    })
    const editId = ref("");
    const filterObject = ref({
      cazeId: props.cazeId,
      policyId: props.policyId,
      claimId: props.claimId,
      companyId: props.companyId
    })
    const contactPage = computed(() => store.state.ContactModule.contactPage);
    return {
      contactForm,
      ...LoadFilterObjects(Actions.LOAD_CONTACTS, filterObject.value, ['provider']),
      contactPage,
      editId,
      ...LoadPanel(),
      filterObject,
    }
  },
  watch: {
    cazeId(cb) {
      this.filterObject.cazeId = cb
      this.updateFilterObject(this.filterObject)
    },
    policyId(cb) {
      this.filterObject.policyId = cb
      this.updateFilterObject(this.filterObject)
    },
    claimId(cb) {
      this.filterObject.claimId = cb
      this.updateFilterObject(this.filterObject)
    },
    companyId(cb) {
      this.filterObject.companyId = cb
      this.updateFilterObject(this.filterObject)
    }
  },
  methods: {
    onContactResponse() {
      this.paginationLoad()
      const baseModal = this.$refs.formContactRef as typeof BaseModal
      baseModal.hideBaseModal()
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await ContactService.delete(id).then(() => {
            this.paginationLoad()
          })
        }
      })
    },
    onAddNew() {
      this.contactForm = {
        cazeId: this.cazeId,
        policyId: this.policyId,
        claimId: this.claimId,
        companyId: this.companyId,
        address: {countryCode: '', stateCode: ''},
        type: 'CONTACT',
        phone: '',
        email: '',
        fax: '',
        name: '',
        details: '',
      }
      this.editId = ""
      const baseModal = this.$refs.formContactRef as typeof BaseModal
      baseModal.showBaseModal()
    },
    onEditContract(contact: any) {
      this.editId = contact.id;
      this.contactForm = {
        cazeId: contact.caze,
        policyId: contact.policy,
        claimId: contact.claim,
        companyId: contact.company,
        address: contact.address ? contact.address : {countryCode: '', stateCode: ''},
        type: contact.type.code,
        phone: contact.phone,
        email: contact.email,
        name: contact.name,
        fax: contact.fax,
        details: contact.details
      }
      const baseModal = this.$refs.formContactRef as typeof BaseModal
      baseModal.showBaseModal()
    }
  }
})
